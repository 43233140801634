<template>
  <div>
    <v-form ref="form" v-model="formValid" @submit.prevent="confirmAlarm">
      <v-card-title class="headline primary white--text">
        {{ $t("alarm.fields.alarmComment") }}
      </v-card-title>
      <v-list>
        <v-list-item v-if="selectedItem">
          <v-list-item-content>
            <v-list-item-subtitle class="d-flex align-center mt-2">
              <span style="font-weight: bold">{{ $t("alarm.name") }}</span
              >:
              {{ selectedItem.alarm.name }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="d-flex align-center mt-2">
              <span style="font-weight: bold">{{
                $t("alarm.fields.message")
              }}</span
              >:
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" style="margin-left: 4px">
                    {{ truncatedMessage }}
                  </span>
                </template>
                <span>"{{ selectedItem.alarm.message }}"</span>
              </v-tooltip>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="d-flex align-center mt-2">
              <span style="font-weight: bold">{{
                $t("alarm.fields.messageSent")
              }}</span
              >:
              <v-icon
                :color="selectedItem.messageSent ? 'success' : 'error'"
                class="ml-2"
              >
                {{
                  selectedItem.messageSent
                    ? "mdi-check-circle"
                    : "mdi-alpha-x-circle"
                }}
              </v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-text>
        <v-text-field
          v-model="confirmationText"
          :label="$t('log.fields.message')"
          :rules="validationRules"
          counter
          maxlength="500"
          clearable
          outlined
        ></v-text-field>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AlarmActive",

  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("alarm.fields.message"),
          value: "alarm.message",
          sortable: false,
        },
        {
          text: this.$t("alarm.fields.messageSent"),
          value: "messageSent",
          sortable: false,
        },
      ],
      confirmationText: "",
      formValid: false,
    };
  },

  computed: {
    ...mapState("alarms", { alarmStatus: "status", alarms: "activeAlarms" }),

    selectedAlarmArray() {
      return this.selectedItem ? [this.selectedItem] : [];
    },

    truncatedMessage() {
      const message = this.selectedItem.alarm.message;
      return message.length > 45 ? message.slice(0, 45) + "..." : message;
    },

    validationRules() {
      return [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) =>
          (v && v.trim().length > 0) ||
          this.$t("alarm.validation.noEmptySpaces"),
        (v) =>
          (v && v.length >= 3) || this.$t("alarm.validation.min", { count: 3 }),
        (v) =>
          (v && v.length <= 500) ||
          this.$t("alarm.validation.max", { count: 500 }),
        (v) =>
          (v && !/^\s*$/.test(v)) ||
          this.$t("alarm.validation.noWhitespaceOnly"),
        (v) =>
          (v && !/(.)\1{4,}/.test(v)) ||
          this.$t("alarm.validation.noExcessiveRepetition"),
        (v) =>
          (v && /[a-zA-Z]/.test(v)) ||
          this.$t("alarm.validation.containsLetter"),
        (v) =>
          (v && !/^[!@#$%^&*(),.?":{}|<>]+$/.test(v)) ||
          this.$t("alarm.validation.notOnlySymbols"),
      ];
    },
  },

  methods: {
    ...mapActions("alarms", ["setAlarmStatus"]),

    async confirmAlarm() {
      if (!this.$refs.form.validate()) return;

      try {
        const alarmCommentPayload = {
          activeAlarmId: this.selectedItem.alarmActiveId,
          comment: this.confirmationText.trim(),
        };
        await this.setAlarmStatus(alarmCommentPayload);
        this.$emit("confirmed");
        this.$store.dispatch("alert/success", this.$t("alarm.affirmation"), {
          root: true,
        });
      } catch (error) {
        console.error(
          "Failed to confirm alarm:",
          error.response ? error.response.data : error
        );
        this.$store.dispatch("alert/error", this.$t("common.error"), {
          root: true,
        });
      }
    },

    reset() {
      this.confirmationText = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>
